import { FaSignInAlt, FaSignOutAlt, FaUser } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../features/auth/authSlice'
import { removeCheckListData } from '../features/checklist/checkListSlice'
import { removeCheckListItemData } from '../features/checklistItem/checkListItemSlice'

function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const onLogout = () => {
    dispatch(logout())
    dispatch(removeCheckListData())
    dispatch(removeCheckListItemData())
    navigate('/login')
  }

  return (
    <header className='header'>
      <div className='logo'>
        <Link to='/'><h2>List-It-All</h2></Link>
      </div>
      <ul>
        {user ? (
          <>
           <li>
           <b> <FaUser /> {user.name} </b>
          </li>
          <li>
            <button className='btn' onClick={onLogout}>
              <FaSignOutAlt /> Logout
            </button>
          </li>
         
          </>
        ) : (
          <>
            {/* <li>
              <Link to='/login'>
                <FaSignInAlt /> Login
              </Link>
            </li> */}
            <li>
              <Link to='/register'>
                <FaUser /> Register
              </Link>
            </li>
          </>
        )}
      </ul>
    </header>
  )
}

export default Header
