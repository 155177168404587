import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/checklist'



// Create new checklistitem
const createOrUpdateACheckListItem = async (checkListItemData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.put(API_URL + "/item", checkListItemData, config)

  return response.data
}

// delete a checklistitem
const deleteACheckListItem = async (checkListItemData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + "/" + checkListItemData.checkListID + "/item/" + checkListItemData.itemID, config)
  return response.data
}

// Get user tickets
const getItemsByCheckListID = async (checkListID,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL+'/'+checkListID, config)

  return response.data
}


const checkListItemService = {
  createOrUpdateACheckListItem,
  deleteACheckListItem,
  getItemsByCheckListID

}

export default checkListItemService
