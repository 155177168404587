import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/checklist'

const createANewCheckList = async (checkListData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, checkListData, config)

  return response.data
}


// delete a checklistitem
const deleteACheckList = async (checkListData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + "/" + checkListData.checkListID, config)

  return response.data
}

// Get user tickets
const getCheckLists = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}



const checkListService = {
  getCheckLists,
  createANewCheckList,
  deleteACheckList

}

export default checkListService
