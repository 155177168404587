import React, { useState } from 'react';
import { AiOutlineEdit, AiFillCheckCircle, AiFillDelete, AiFillCloseCircle } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux'
import {  createOrUpdateACheckListItem,deleteACheckListItem } from '../features/checklistItem/checkListItemSlice';
import { toast } from 'react-toastify'

const textareaStyle = {
    width: '100%', // Set the desired width here
    height: '100px', // Set the desired height here
};



function CheckListItem({ item }) {
    
    const [Item, setItem] = useState(item);
    //console.log(Item)
   
    const [isEditing, setIsEditing] = useState(Item.isEditing);

    const [checked,setChecked]=useState(Item.status=='completed'?true:false)

    const dispatch=useDispatch()

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCloseClick = () => {
        
        if(Item._id){

        }
        else{

            setItem({...Item,description:''});
        }

        setIsEditing(false);
    };

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
      
         setChecked(isChecked)
         setIsEditing(true)
      
      };

    const handleSaveClick = () => {
        if(Item.description && Item.description.length>0){
        dispatch(createOrUpdateACheckListItem({  checkListID: Item.checkListID,description:Item.description,status:checked?"completed":"pending", itemID: Item._id }))
        .unwrap()
        .then(() => {
            // We got a good response so navigate the user
            //   navigate('/checklist')
            toast.success('saved')
        })
        .catch(toast.error)
        setIsEditing(false);
        //onSave(text);
    }
    };
    const handleDeleteClick = () => {
       
        if(Item._id){
        dispatch(deleteACheckListItem({ checkListID: Item.checkListID, itemID: Item._id }))
        .unwrap()
        .then(() => {
            // We got a good response so navigate the user
            //   navigate('/checklist')
          //  updatedTextBoxes.splice(index, 1)
           // setTextBoxes(updatedTextBoxes);
           console.log('deleeee')
            toast.success('deleted')
        })
        .catch(toast.error)
        setIsEditing(false);
       // onDelete();
    }
    };


    const handleChange = (event) => {
        // Limit text to 100 characters per line
        // const newText = event.target.value
        //     .split('\n')
        //     .map((line) => line.slice(0, 400))
        //     .join('\n');
        setItem({...Item,description:event.target.value});
    };

    const urlify=(text)=> {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex)
           .map(part => {
              if(part.match(urlRegex)) {
                 return <a href={part} target="_blank">{part}</a>;
              }
              return part;
           });
        }

    // return (
    //     <div>
    //         {JSON.stringify(Item)}
    //     </div>
    // )
    return (
       
         (!Item)?(<div><h1>No items yet...</h1></div>):
         ( <div className="editable-textbox">
         <div className="checkbox">
             <input type="checkbox" className='rounded-checkbox' checked={checked} onChange={handleCheckboxChange} />
         </div>
         <div className={`box ${isEditing ? 'editing-mode' : 'view-mode'}`}>
             {isEditing ? (
                 <div className="editing-content">
                     <textarea
                         rows="10"
                         cols="400"
                         value={Item.description}
                         onChange={handleChange}
                         style={textareaStyle}
                     />
                     <button onClick={handleSaveClick} className="save-button">
                         <AiFillCheckCircle />
                     </button>
                     <button onClick={handleCloseClick} className="close-button">
                         <AiFillCloseCircle />
                     </button>

                 </div>
             ) : (
                 <div className="view-content">
                     <div className={`text-display ${checked ? 'completed' : ''}`}>{urlify(Item.description)}</div>
                     <button onClick={handleEditClick} className="edit-button">
                         <AiOutlineEdit />
                     </button>
                     <button onClick={handleDeleteClick} className="delete-button">
                         <AiFillDelete />
                     </button>
                 </div>
             )}
         </div>
     </div>)

       
    );
}

export default CheckListItem;
