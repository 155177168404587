import React from 'react';


const FloatingButton = ({ onClick, icon }) => {
  return (
    <button className="floating-button" onClick={onClick}>
      {icon}
    </button>
  );
};

export default FloatingButton;
