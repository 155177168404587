import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/user/'

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL+'register', userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }
  return response.data
}

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }
  return response.data
}

// Login using google user
const googleLogin = async () => {
 // const response = await axios.get(API_URL + 'auth/google',{ maxRedirects: 2 })
 window.open(`${process.env.REACT_APP_API_URL}/auth/google`, '_self');
  // if (response.data) {
  //   localStorage.setItem('user', JSON.stringify(response.data))
  // }
  // return response.data
}
// Logout user
const logout = () => localStorage.removeItem('user')



const authService = {
  register,
  logout,
  login,
  googleLogin
}

export default authService
