import { useDispatch } from "react-redux";
import { filterItems } from "../features/checklistItem/checkListItemSlice";

const Chip = ({ label, onDelete, onClick}) => {

  const dispatch=useDispatch()

  const onClickHandler=(e)=>{
    
      dispatch(filterItems({status:label}))
    
  }

    return (
      <div className="chip" onClick={onClickHandler}>
        <span>{label}</span>
        <button onClick={onDelete}>&times;</button>
      </div>
    );
  };

  export default Chip