import React, { useEffect, useState } from 'react';
import CheckListItem from '../components/CheckListItem';
import { AiOutlinePlus } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux'

import { createANewCheckList, addNewChecklistLocally, deleteACheckList } from '../features/checklist/checkListSlice';
import { deleteACheckListItem ,addNewChecklistItemLocally} from '../features/checklistItem/checkListItemSlice';
import { toast } from 'react-toastify'
import CheckListTitle from '../components/CheckListTitle';

const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10vh',  // Set a specific value for height
    marginTop: '50px'

};

const textStyles = {
    textAlign: 'center',
    fontSize: '24px',
    textStyles: 'bold'

};

function CheckList({ checklist, enableAddButton }) {


    const dispatch = useDispatch()
    
    let checkListItems = useSelector((state) => state.checkListItems.checkListItems.filter(item => item.checkListID === checklist._id))
    let filteredCheckListItems = useSelector((state) => state.checkListItems.filteredItems.filter(item => item.checkListID === checklist._id))
    const isFilterApplied = useSelector((state) => state.checkListItems.isFilterApplied)
    let items=null
    
    if(isFilterApplied===false){
        items=checkListItems
       
    }
    else{
        items=filteredCheckListItems
       
    }
    const sortedData = items.sort((a, b) => (a.status === 'pending' ? -1 : 1));
    items=sortedData

    // Sorting the array by the 'status' field
        

    let [textBoxes, setTextBoxes] = useState(filteredCheckListItems);


    const handleAddCheckListItem = () => {
       // const newCheckListItem = [...textBoxes, { description: '', status: "pending" }]
        //setTextBoxes(newCheckListItem);

        dispatch(addNewChecklistItemLocally({ description: '', status: "pending",checkListID:checklist._id,isEditing:true }))

    };

    
         const handleCheckListDelete = () => {
        // const updatedTextBoxes = [...textBoxes];
        //updatedTextBoxes[index] = { ...textBoxes[index], description, status: updatedTextBoxes[index].status };

        // console.log(updatedTextBoxes[index])

        // e.preventDefault()
        dispatch(deleteACheckList({ checkListID: checklist._id }))
            .unwrap()
            .then(() => {
                // We got a good response so navigate the user
                //   navigate('/checklist')
                //  updatedTextBoxes.splice(index, 1)
                //   setTextBoxes(updatedTextBoxes);
                toast.success('deleted')
            })
            .catch(toast.error)

        //  console.log("delete :" + index)

    };



    const handleCheckListTitleTextBoxSave = async (name) => {

        const newChecklist = { name, status: 'pending', items: [], checkListID: checklist._id }


        dispatch(createANewCheckList(newChecklist))
            .unwrap()
            .then(() => {
                // We got a good response so navigate the user
                //   navigate('/checklist')
                checklist = null
                toast.success('saved')
            })
            .catch(toast.error)

    };

    const handleCheckListTitleCheckboxChange = () => {

        // const updatedTextBoxes = [...textBoxes];
        // updatedTextBoxes[index] = { ...textBoxes[index], description: updatedTextBoxes[index].description, status: updatedTextBoxes[index].status === "pending" ? "completed" : "pending" };
        // setTextBoxes(updatedTextBoxes);


    };

    const handleAddCheckListBox = () => {

        dispatch(createANewCheckList({ name: "test", status: 'pending' }))
            .unwrap()
            .then(() => {
                // We got a good response so navigate the user
                //   navigate('/checklist')
                toast.success('created')
            })
            .catch(toast.error)
    }

    // return(
    //     <div>
    //         {"checklistID:"+checklist._id+"items:::"+JSON.stringify(filteredCheckListItems)}
    //     </div>
    // )

    return (
        <div className="App">
            <div style={containerStyle}>
                {/* <div style={textStyles}>
                    < CheckListItem
                        initialValue={checklist.name}
                        isChecked={checklist.status === "pending" ? false : true}
                        onSave={(description) => handleCheckListTitleTextBoxSave(description)}
                        onDelete={() => handleCheckListDelete()}
                        onCheckboxChange={() => handleCheckListTitleCheckboxChange()}
                    />
                </div> */}
               <CheckListTitle checklist={checklist}/>
            </div>
            <h2>
            </h2>

            <>
                
            </>
            <div className={enableAddButton ? "text-box-container" : ""}> {/* Container for all the CheckListItem components */}
                {items.map((checkListItem, index) => (

                    < CheckListItem
                        key={checkListItem._id}
                        item={checkListItem}
                    />
                ))}
                {enableAddButton &&
                    <button className="add-button" onClick={handleAddCheckListItem}>
                        <AiOutlinePlus />
                    </button>}
            </div>

        </div >
    );
 }

export default CheckList;
