import React from 'react';
import { FaGoogle } from 'react-icons/fa';

const GoogleSignInButton = ({onClick}) => {
  const handleSignIn = () => {
    // Add your Google sign-in logic here
    console.log('Signing in with Google...');
  };

  return (
    <button
      className='btn-block google-button'
      onClick={onClick}
    >
      <FaGoogle style={{ marginRight: '8px' }} />
      Sign In with Google
    </button>
  );
};

export default GoogleSignInButton;
