import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom'
import {setUser} from '../features/auth/authSlice'


const GoogleLogin = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    searchParams.get("username")
    const dispatch =useDispatch()
    const navigate=useNavigate()


    useEffect(()=>{
        console.log("glogin called")
        const username=searchParams.get("username")
        const email=searchParams.get("email")
        const token=searchParams.get("token")
        if(username)
        dispatch(setUser({name:username,email:email,token:token}))
        navigate('/')
    },[])

  return (
    <div>
     
    </div>
  )
}
export default GoogleLogin
