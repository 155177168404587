import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header'
import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import CheckList from './pages/CheckList'
import CheckLists from './pages/CheckLists'
import PrivateRoute from './components/PrivateRoute'
import GoogleLogin from './components/GoogleLogin'




// NOTE: Here we have removed the nested routing as the path is the same

function App() {



  return (
    <>
      <Router>
        <div className='container'>
          <Header />
                    
          <Routes>
            <Route path='/' element={<PrivateRoute />} >
              <Route path='/' element={<CheckLists />} />
            </Route>

            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/google/auth/success' element={<GoogleLogin/>}/>

          </Routes>
        </div>
      </Router>
      <ToastContainer position="bottom-center" // Set the default position
        autoClose={500} />
    </>
  )
}

export default App
