import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice'
import checkListReducer from '../features/checklist/checkListSlice'
import  checkListItemsReducer  from '../features/checklistItem/checkListItemSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    checkLists: checkListReducer,
    checkListItems:checkListItemsReducer
  },
});
