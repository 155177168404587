import React, { useState } from 'react';
import EditableTextBox from '../components/CheckListItem';
import { AiOutlinePlus } from 'react-icons/ai';
import CheckList from './CheckList';
import { getCheckLists, createACheckList,addNewChecklistLocally, removeCheckListData } from '../features/checklist/checkListSlice'
import Spinner from '../components/Spinner';
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import FloatingButton from '../components/FloatingButton';
import Chip from '../components/Chip'
import { useNavigate } from 'react-router-dom'
import { setUser,logout } from '../features/auth/authSlice';
import { removeCheckListItemData } from '../features/checklistItem/checkListItemSlice';


function CheckLists() {


    const { checkLists } = useSelector((state) => state.checkLists)
    const { isLoading } = useSelector((state) => state.checkListItems)
    
    const dispatch = useDispatch()
    const [createNewChecklist, setcreateNewChecklist] = useState(false)
    const navigate = useNavigate()
        
    // NOTE: only need one useEffect here


    useEffect(() => {
        dispatch(getCheckLists())
        .unwrap()
        .then(() => {
          // NOTE: by unwrapping the AsyncThunkAction we can navigate the user after
          // getting a good response from our API or catch the AsyncThunkAction
          // rejection to show an error message
         // toast.success(`Logged in as ${user.name}`)
          //navigate('/')
        })
        .catch((err)=>{
           
             if(err.httpStatusCode===401)
          {
                dispatch(setUser(null))
                dispatch(removeCheckListData())
                dispatch(removeCheckListItemData())
                dispatch(logout())
               navigate('/login')
          }
          else{

          }
            toast.error(err)
        })
        console.log('useeffect of getChecklist ws called')
    }, [])


    const handleCheckListAdd=()=>{
        dispatch(addNewChecklistLocally({name:'',status:'pending'}))
    }


    const handleAddCheckListBox = () => {
        setcreateNewChecklist(true)
    }
    // NOTE: no need for loading state, we can check for absence of tickets
    // If we don't have tickets we are loading, if we do have tickets we just
    // need to update the tickets with latest tickets in the background
    if (!checkLists) {
        return <Spinner />
    }

  
    return (
        <div className="App">

        <>
            {(checkLists.length === 0) ? (<h3>No Checklists created yet...</h3>)
                : (<div className='content-container'>
                   <Chip label="pending"/>
                    <Chip label="completed"/>
                    <Chip label="All"/>
                    {checkLists.map((checkList, index) => (
                        <CheckList key={checkList._id} checklist={checkList} enableAddButton={true} />
                    ))}

                </div>)}


         
         </>
         <FloatingButton onClick={handleCheckListAdd} icon="+" />

        </div>
    );
}

export default CheckLists;
