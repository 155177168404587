import React, { useState } from 'react';
import { AiOutlineEdit, AiFillCheckCircle, AiFillDelete, AiFillCloseCircle } from 'react-icons/ai';
import {  useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { createANewCheckList,deleteACheckList } from '../features/checklist/checkListSlice';

const textareaStyle = {
    width: '100%', // Set the desired width here
    height: '100px', // Set the desired height here
};



function CheckListTitle({ checklist }) {
    
    const [Item, setItem] = useState(checklist);
    //console.log(Item)
   
    const [isEditing, setIsEditing] = useState(Item.isEditing);

    const [checked,setChecked]=useState(Item.status === 'completed' ? true:false)

    const dispatch=useDispatch()

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCloseClick = () => {
        setIsEditing(false);
    };

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
      
         setChecked(isChecked)
         if(isChecked)setIsEditing(true)
         else setIsEditing(false)
      
      };

    const handleSaveClick = () => {
        dispatch(createANewCheckList({  checkListID: Item._id,name:Item.name,status:checked?"completed":"pending"}))
        .unwrap()
        .then(() => {
            // We got a good response so navigate the user
            //   navigate('/checklist')
            toast.success('saved')
        })
        .catch(toast.error)
        setIsEditing(false);
        //onSave(text);
    };
    const handleDeleteClick = () => {
       
        dispatch(deleteACheckList({ checkListID: Item._id}))
        .unwrap()
        .then(() => {
            // We got a good response so navigate the user
            //   navigate('/checklist')
          //  updatedTextBoxes.splice(index, 1)
           // setTextBoxes(updatedTextBoxes);
           console.log('deleeee')
            toast.success('deleted')
        })
        .catch(toast.error)
        setIsEditing(false);
       // onDelete();
    };

    const handleChange = (event) => {
        // Limit text to 100 characters per line
        const newText = event.target.value
            .split('\n')
            .map((line) => line.slice(0, 100))
            .join('\n');
           // console.log(Item.checkListID)
        setItem({...Item,name:newText});
    };

    // return (
    //     <div>
    //         {JSON.stringify(Item)}
    //     </div>
    // )
    return (
       
         (!Item)?(<div><h1>No items yet...</h1></div>):
         ( <div className="editable-textbox">
         {/* <div className="checkbox">
             <input type="checkbox" className='rounded-checkbox' checked={checked} onChange={handleCheckboxChange} />
         </div> */}
         <div className={`box ${isEditing ? 'editing-mode' : 'view-mode'}`}>
             {isEditing ? (
                 <div className="editing-content">
                     <textarea
                         rows="10"
                         cols="400"
                         value={Item.name}
                         onChange={handleChange}
                         maxLength={800} // Set an overall character limit (e.g., 4 lines of 100 characters each)
                         style={textareaStyle}
                     />
                     <button onClick={handleSaveClick} className="save-button">
                         <AiFillCheckCircle />
                     </button>
                     <button onClick={handleCloseClick} className="close-button">
                         <AiFillCloseCircle />
                     </button>

                 </div>
             ) : (
                 <div className="view-content">
                     <div className={`bold-font ${checked ? 'completed' : ''}`}>{Item.name}</div>
                     <button onClick={handleEditClick} className="edit-button">
                         <AiOutlineEdit />
                     </button>
                     <button onClick={handleDeleteClick} className="delete-button">
                         <AiFillDelete />
                     </button>
                 </div>
             )}
         </div>
     </div>)

       
    );
}

export default CheckListTitle;
